import BaseBean from "@/utils/BaseBean";
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
import XyzCard from "@/views/project/order/finance/xyz/xyz/XyzCard.vue";
import OaApplyCard from "@/views/project/order/finance/oa/oaApply/OaApplyCard.vue";
import TtPayCard from "@/views/project/order/finance/tt/ttPay/TtPayCard.vue";
export interface IDdjsListDataObj {
    utilInst:DdjsListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class DdjsListUtil extends BaseBean{
    public dataObj:IDdjsListDataObj

    constructor(proxy:any,dataObj:IDdjsListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});//查询所有客户
    }
    //订单结算
    public async ddjsHandler():Promise<any>{
        let selections:Array<any>=this.dataObj.pageListRef.getTbInst().getSelection();
        if(selections.length==0){
            this.proxy.$message('请选择要操作的行');
            return ;
        }
        this.dataObj.otherParams.selInfo.F_PI_ID=selections[0].F_PI_ID;
        this.dataObj.otherParams.selInfo.F_ORDER_CODE=selections[0].F_ORDER_CODE;
        this.dataObj.otherParams.selInfo.F_YFK=selections[0].F_YFK;
        this.dataObj.otherParams.selInfo.F_WK=selections[0].F_WK;
        this.dataObj.otherParams.selInfo.F_AMOUNT=selections[0].F_YFK+selections[0].F_WK;
        this.dataObj.otherParams.dialogVisible=true;
    }
    //查看pi弹出框
    public async piDetail(piId:string):Promise<any>{
        await this.utils.UtilPub.openDialog({proxy:this.proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:this.proxy,cardFrom:'SkqrList',
            title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
    }
    //订单结算发起请求处理
    public async doDdjs():Promise<any>{
        let selections:Array<any>=this.dataObj.pageListRef.getTbInst().getSelection();
        let params={id:selections[0].F_ID};
        let res=await this.utils.OrderProviderApi.ddjsAction({params:params});
        if(res.result){
            this.dataObj.pageListRef.queryHandler(true);
            this.utils.Tools.success();
            this.dataObj.otherParams.dialogVisible=false;
        }
    }

    //查看支付详情弹出框
    public async payDetail(row:any,type:number):Promise<any>{
        let id=row.F_YFK_PAY_ID;
        let payType=row._F_YFK_PAY_TYPE;//支付方式
        let modelComp;//打开的弹出框是TT、信用证、OA
        if(1==type) {//尾款
            id=row.F_WK_PAY_ID;
            payType=row._F_WK_PAY_TYPE;
        }
        switch (payType) {
            case 0://TT
                let res1=await this.utils.OrderProviderApi.getTtPayIdByDetailId({params:{id:id}});
                id=res1.id;
                modelComp=TtPayCard; break;
                break;
            case 1://信用证
                let res2=await this.utils.OrderProviderApi.getXyzIdByDetailId({params:{id:id}});
                id=res2.id;
                modelComp=XyzCard; break;
            case 2://OA
                let res3=await this.utils.OrderProviderApi.getOaApplyIdByDetailId({params:{id:id}});
                id=res3.id;
                modelComp=OaApplyCard;break;
        }
        await this.utils.UtilPub.openDialog({proxy:this.proxy,addOrEdit:'edit',id:id,modelComp:modelComp,ownerComp:this.proxy,cardFrom:'DdjsList',
            title:'支付详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
    }
}